@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Homemade Apple', 'Dancing Script', cursive,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content_fix {
  max-width: 240px;
  display: inline-block;
  white-space: break-spaces;
}


.ant-switch.ant-switch-checked {
  background: #0f2f82;
}

.main-sidemenu {
  height: 100vh;
  overflow: scroll;
}

.css-11unzgr {
  max-height: 150px !important;
  overflow-y: scroll !important;
}


.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: none;

}

.modal-backdrop.show {
  display: block;
}

.horizontal-scroll {
  overflow-x: auto;
  max-height: 300px;
}

.horizontal-scroll1 {
  overflow-x: auto;
  max-height: 100px;
}

.flex-containerss {

  display: inline-block;
  white-space: break-spaces;
}

.signature {
  display: block;
  /* margin: 100px auto; */
  border: 2px solid #4239a4;
  width: 500px;
  height: 200px;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
  z-index: 2 !important;
}

.addMoreLeavers {
  background: #0f2f81;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  width: 160px;
  margin-top: 25px;
  height: 45px;
  line-height: 20px;
}

.leavesManage span {
  cursor: pointer;
}

.leavesManage.number {
  margin: 0px;
  margin-bottom: 20px;
}

.leavesManage .minus {
  width: 30px;
  height: 30px;
  background: #0F2F82;
  border-radius: 0px;
  padding: 6px 6px 6px 6px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.leavesManage .plus {
  width: 30px;
  height: 30px;
  background: #0F2F82;
  border-radius: 0px;
  padding: 6px 6px 6px 6px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.leavesManage .minus i {
  color: #fff;
}

.leavesManage .plus i {
  color: #fff;
}

.leavesManage input {
  height: 30px;
  width: 40px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 0px;
  display: inline-block;
  vertical-align: middle;
}











.payslip {
  width: 1000px;
  margin: 0 auto;
  border: 1px solid #828080;
  padding: 10px;
  box-sizing: border-box;
}

/* .salary-header {
    text-align: center;
    margin-bottom: 20px;
} */

.salary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logo-group {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo {
  margin-right: 10px;
}

.title-group {
  text-align: center;
  text-transform: uppercase;

}

.page-info {
  text-align: right;
}

.Adjust-button {
  height: max-content;
  /* margin-bottom: 5px; */
  margin-top: 15px;
}

/* table,
th,
tr,
thead,
tbody:hover {
  transform: none !important;
  transition: none !important;
} */

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: inherit !important;
  color: var(--bs-table-hover-color);
}

@media (max-width: 768px) {
  .salary-header {
    flex-direction: column;
    align-items: center;
  }

  .title-group,
  .page-info {
    text-align: center;
    margin-top: 10px;
  }

  .page-info {
    margin-top: 5px;
  }
}


.salary-header img {
  max-height: 50px;
  vertical-align: middle;
}

.salary-header h1 {
  display: inline;
  margin: 0 20px;
  vertical-align: middle;
}

.employee-details,
.summary {
  margin-bottom: 10px;

}

.details {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.details th,
.details td {
  border: 1px solid #a8a6a6;
  padding: 8px;
  text-align: left;
}

.details th {
  background-color: #f2f2f2;
}

.salary-footer {
  text-align: left;
  display: flex;
  font-size: small;
  justify-content: space-between;
}





.dropdown-item5 {
  white-space: normal; /* allows the text to wrap to the next line */
  text-overflow: ellipsis; /* adds an ellipsis (...) when the text is too long */
  overflow: hidden; /* hides the excess text */
  max-width: 200px; /* sets the maximum width of the dropdown item */
}



.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  border-bottom: 1px solid #5211dc; /* add a horizontal line to the bottom of each grid container */
  padding-bottom: 20px; /* add some padding to the bottom to make the line visible */
  margin-bottom: 20px;
  
}



.grid-item {
  display: flex;
  flex-direction: column;
}

.grid-item:nth-child(2) {
  overflow-y: auto;
  max-height: 100px; /* adjust the max height as needed */
}



.custom-list li::marker {
  color: #007bff; /* Change the color to blue */
}

.borderBottom{
  border-bottom: 1px solid #e9e3e3;
} 

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  margin-top: 0; /* Adjusts the position of the dropdown */
}

.checkbox-container {
  display: flex; /* Use flexbox to arrange items in a row */
  flex-wrap: wrap; /* Allow wrapping to the next line if needed */
  gap: 10px; /* Add some space between checkboxes */
}

.custom-control {
  display: flex; /* Ensure the label is a flex container */
  align-items: center; /* Center align items vertically */
}
