.text-danger {
    color: red;
    position: relative;
}
.display-block {
    display: block !important;
  }
  
  .display-none {
    display: none !important;
  }
  
  .text-left {
	  text-align:left;
  }
  .f-16 {
	  font-size:16px;
  }
  .lendelist-status {
      padding: 15px 0px;
      padding-right: 15px;
      padding-bottom: 0px;
          padding-top: 10px;
  }
  button.checkstatus {
    padding: 0;
    background-color: #00c18d !important;
    border: 0;
    -webkit-appearance: none;
    color: #fff !important;
    width: 25px !important;
    height: 25px !important;
    border-radius: 5px !important;
    font-size: 18px !important;
    line-height: 15px !important;
    padding: 0px !important;
    text-align: center !important;
}
  
@media (max-width: 767px) {
  .w30 + label:before {
    width: 25.5px !important;
}
}